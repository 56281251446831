
import Component, { mixins } from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";
import { FormValidations } from "@/mixins/form-validations";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class LanguagePicker extends mixins(
  FormValidations,
  StyleCheck
) {
  @PropSync("value", { type: String })
  syncedValue!: string;
  @Prop({ default: false })
  readonly!: boolean;
  @Prop({ default: "8rem" })
  width!: string;
  @Prop({ default: false })
  long!: boolean;

  get languages() {
    return [
      {
        text: this.long ? this.$tc("Views.lp-2") : "SPA",
        value: "es",
      },
      { text: this.long ? this.$tc("Views.lp-1") : "ENG", value: "en" },
    ];
  }
  created() {
    if (!this.syncedValue) {
      const language = this.$store.getters["language/getLanguage"];
      this.syncedValue = language;
    }
  }
  handleChange(value: string) {
    if (value) {
      this.$i18n.locale = value;

      this.$store.dispatch("language/setLanguage", value);
    }
  }
}
