
import Component, { mixins } from "vue-class-component";
import Offline from "v-offline";
import { SystemError as SysError } from "@/models/system-error.interface";
import { CustomDate } from "@/mixins/custom-date";

@Component({
  components: {
    Offline,
  },
})
export default class ConnectionStatus extends mixins(CustomDate) {
  private handleConnectivityChange(status) {
    if (!status) {
      if (process.env.VUE_APP_STAGE == "PRODUCTION") {
        const Error: SysError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: this.$tc("Errors.App.offlineTraceback"),
          errorMessage: this.$tc("Errors.App.offlineMessage"),
          criticalError: true,
          description: this.$tc("Errors.App.offline"),
        };
        this.$store.dispatch("notifications/setShowNotification", false);
        this.$store.dispatch("notifications/showSystemError", Error);
      } else {
        alert("CONNECTION ERROR DETECTED");
      }
    }
  }
}
