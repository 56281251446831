
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import NavigationForClient from "@/components/Layout/Client/NavigationForClient.vue";
import NavigationForUserNotLogged from "@/components/Layout/Client/NavigationForUserNotLogged.vue";
import SearchBar from "@/components/General/SearchBar.vue";
import { Company } from "@/models/company.interface";
import { UserValidations } from "@/mixins/user-validations";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import { Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    NavigationForClient,
    NavigationForUserNotLogged,
    SearchBar,
    LocaleSwitcher,
  },
})
export default class Header extends mixins(
  UserValidations,
  Navigation,
  StyleCheck
) {
  value = 0;
  query = false;
  show = false;
  onlyLogo = false;

  private searchProduct(productName: string) {
    if (productName !== "") {
      this.query = true;
      this.show = true;
      this.value = 0;

      setInterval(() => {
        this.value += 17;
      }, 250);

      setTimeout(() => {
        this.query = false;
        this.show = false;
        this.navigate("/catalogue/search/" + productName + "/" + 1);
      }, 1500);
    }
  }

  private openSideBar(): void {
    this.$emit("openSideBar");
  }

  get iconWidth(): number {
    return this.$vuetify.breakpoint.xsOnly ? 70 : 200;
  }
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  private get company(): Company {
    return this.$store.getters["system/getCompany"];
  }

  @Watch("functionalities")
  private functionalityHandler() {
    for (let index = 0; index < this.functionalities.length; index++) {
      if (
        this.functionalities[index].name ==
        this.$constants.FUNCTIONALITY.ONLY_LOGO
      ) {
        this.onlyLogo =
          this.functionalities[index].status?.name ==
          this.$constants.STATUS.ACTIVE
            ? true
            : false;
      }
    }
  }

  private get functionalities(): any[] {
    return this.$store.getters["functionalities/getFunctionalities"];
  }

  private created() {
    this.functionalityHandler();
  }
}
