
import Component, { mixins } from "vue-class-component";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import FooterLinks from "@/components/Layout/Client/FooterLinks.vue";
import { Notification } from "@/models/notification.interface";
import { SocialNetwork } from "@/models/social-network.interface";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    LocaleSwitcher,
    FooterLinks,
  },
})
export default class Footer extends mixins(StyleCheck) {
  private async created() {
    await this.$store
      .dispatch("socialNetworks/getSocialNetworksToClient")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("SocialNetworks.fetchError.socialNetworks"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get socialNetworks(): SocialNetwork[] {
    let response = this.$store.getters["socialNetworks/getSocialNetworks"];
    if (response?.socialNetworks) {
      return response.socialNetworks;
    } else {
      return [];
    }
  }
}
