
import Vue from "vue";
import Component from "vue-class-component";
import { SystemError as SysError } from "@/models/system-error.interface";

@Component({})
export default class SystemError extends Vue {
  showDetail = false;

  private get showSystemError(): boolean {
    return this.$store.getters["notifications/getShowError"];
  }

  private get errorInfo(): SysError {
    return this.$store.getters["notifications/getSystemError"];
  }

  private closeSystemError(): void {
    if (this.errorInfo.criticalError) {
      location.reload();
    } else {
      this.$store.dispatch("notifications/setShowError", false);
    }
  }
}
