
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class NavigationForClient extends mixins(
  Navigation,
  StyleCheck
) {
  private get userAlias(): string {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.alias;
    } else {
      return "";
    }
  }

  private async logOut() {
    this.navigate("/home");
    await this.$store.dispatch("authentication/logOut").then(() => {
      location.reload();
    });
  }
}
