
import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class NavigationForUserNotLogged extends mixins(
  Navigation,
  StyleCheck
) {
  get phoneSize() {
    return this.$vuetify.breakpoint.xsOnly;
  }
  get iconSize() {
    return this.phoneSize ? 26 : 18;
  }
}
