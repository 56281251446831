
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { FormValidations } from "@/mixins/form-validations";
import { CustomDate } from "@/mixins/custom-date";
import { Notification } from "@/models/notification.interface";
import { StyleCheck } from "@/mixins/style-check";
import LanguagePicker from "@/components/Input/LanguagePicker.vue";

@Component({
  components: {
    LanguagePicker,
  },
})
export default class NewsletterDialog extends mixins(
  FormValidations,
  CustomDate,
  StyleCheck
) {
  @Prop() newsletterDialog = false;
  dialog = false;
  loading = false;
  showAlert = false;

  newsletterDefault: any = {
    first_name: "",
    last_name: "",
    email: "",
    language: "es",
  };

  newsletter = { ...this.newsletterDefault };

  $refs!: {
    newsletterForm: HTMLFormElement;
  };

  @Watch("newsletterDialog")
  private dialogHandler(): void {
    if (this.newsletterDialog) {
      this.dialog = true;
    } else {
      this.showAlert = false;
      this.dialog = false;
    }
  }

  @Watch("dialog")
  private closedDialog() {
    if (!this.dialog) {
      this.showAlert = false;
      this.$emit("closeDialog");
    }
  }

  private async subscribe() {
    this.showAlert = false;
    this.loading = true;
    if (this.$refs.newsletterForm.validate()) {
      const newsletter: any = {
        first_name: this.newsletter.first_name,
        last_name: this.newsletter.last_name,
        email: this.newsletter.email,
        date: this.currentDate(),
        language: this.newsletter.language,
      };
      await this.$store
        .dispatch("newsletter/subscribe", { newsletter: newsletter })
        .then(async (response) => {
          if (response.new) {
            const Success: Notification = {
              message: this.$tc("Newsletter.NDsuccess"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: false,
            };

            this.$store.dispatch("notifications/showNotification", Success);
            this.close();
          } else {
            this.showAlert = true;
          }
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Newsletter.NDerror"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private close() {
    this.newsletter = Object.assign(this.newsletter, this.newsletterDefault);
    this.showAlert = false;
    this.resetFormValidations([this.$refs.newsletterForm]);
    this.$emit("closeDialog");
  }
}
