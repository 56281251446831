
import Vue from "vue";
import Component from "vue-class-component";
import BillChat from "../Input/BillChat.vue";
import { Socket } from "vue-socket.io-extended";

@Component({
  components: {
    BillChat,
  },
})
export default class ChatNotification extends Vue {
  newMessageNotification = false;
  billIDMessage = "";
  show = false;

  @Socket("chat:updated")
  private async chatsWereUpdated(payload) {
    if (
      this.context == this.$constants.CONTEXT.FRONTOFFICE &&
      !this.usualChatOpened
    ) {
      if (payload.client_id == this.userInfo.id && !this.show) {
        this.billIDMessage = payload.bill_id;
        this.newMessageNotification = true;
      }
    }
  }

  private get usualChatOpened() {
    return this.$store.getters["notifications/getChatOpened"];
  }

  private closeNotification() {
    this.billIDMessage = "";
    this.show = false;
    this.newMessageNotification = false;
    this.$store.dispatch("notifications/setChatOpened", false);
  }

  private openChat() {
    this.newMessageNotification = false;
    this.show = true;
    this.$store.dispatch("notifications/setChatOpened", true);
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }

  private get userInfo(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user;
    } else {
      return "";
    }
  }
}
