
import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    LocaleSwitcher,
  },
})
export default class SimpleHeader extends mixins(Navigation, StyleCheck) {
  private navigatePrevious() {
    this.navigate(
      this.$route.meta?.previousRoute
        ? this.$route.meta.previousRoute
        : this.context == this.$constants.CONTEXT.FRONTOFFICE
        ? "/home"
        : this.context !== null
        ? "/staff/home"
        : "/home"
    );
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }
}
