
import { Navigation } from "@/mixins/navigation";
import { UserValidations } from "@/mixins/user-validations";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({})
export default class BottomNavigation extends mixins(
  UserValidations,
  Navigation
) {
  option = "explore";

  @Watch("option")
  private optionChange() {
    this.navigate(this.option);
  }
}
