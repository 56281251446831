
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import SearchBar from "@/components/General/SearchBar.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({ components: { SearchBar } })
export default class SideBar extends mixins(Navigation, StyleCheck) {
  @Prop() drawer!: boolean;
  readyOpen = false;

  value = 0;
  query = false;
  show = false;

  private get userAlias(): string {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.alias;
    } else {
      return "";
    }
  }

  @Watch("drawer")
  private openSidebar(): void {
    this.readyOpen = !this.readyOpen;
  }

  private async logOut() {
    await this.$store.dispatch("authentication/logOut").then(() => {
      this.$emit("close");
      this.navigate("/home");
    });
  }
  private searchProduct(productName: string) {
    if (productName !== "") {
      this.query = true;
      this.show = true;
      this.value = 0;

      setInterval(() => {
        this.value += 17;
      }, 250);

      setTimeout(() => {
        this.query = false;
        this.show = false;
        this.navigate("/catalogue/search/" + productName + "/" + 1);
      }, 1500);
    }
  }
}
