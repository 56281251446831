
import Component from "vue-class-component";
import Vue from "vue";
import AdministratorSideBar from "@/components/Layout/Backoffice/AdministratorSideBar.vue";

@Component({
  components: {
    AdministratorSideBar,
  },
})
export default class AdministratorHeader extends Vue {
  private openSideBar(): void {
    this.$emit("openSideBar");
  }
}
