
import Vue from "vue";
import Component from "vue-class-component";
import { Notification } from "@/models/notification.interface";

@Component({})
export default class SystemBar extends Vue {
  private created() {
    this.getMessageOfTheDay();
  }

  private async getMessageOfTheDay(): Promise<void> {
    await this.$store
      .dispatch(
        "settings/getMessageOfTheDay",
        this.$constants.PAGE.MESSAGE_OF_THE_DAY
      )
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Home.fetchError.messageOfTheDay"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private closeMessageOfTheDay() {
    this.$store.dispatch("settings/setMessageOfTheDayState", false);
  }

  private get showBar() {
    if (this.messageOfTheDay !== "" && this.messageOfTheDayState) {
      return true;
    } else {
      return false;
    }
  }

  private get messageOfTheDay() {
    const message = this.$store.getters["settings/getMessageOfTheDay"].value;
    return message;
  }

  private get messageOfTheDayState() {
    return this.$store.getters["settings/getMessageOfTheDayState"];
  }
}
