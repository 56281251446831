
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class GlobalLoader extends Vue {
  @Prop() loading!: boolean;

  private reloadPage(): void {
    location.reload();
  }
}
