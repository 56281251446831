
import { Navigation } from "@/mixins/navigation";
import { StyleCheck } from "@/mixins/style-check";
import Component, { mixins } from "vue-class-component";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import { Prop, Watch } from "vue-property-decorator";
import { User } from "@/models/user.interface";

@Component({
  components: {
    LocaleSwitcher,
  },
})
export default class AdministratorSideBar extends mixins(
  Navigation,
  StyleCheck
) {
  @Prop() drawer!: boolean;
  readyOpen = this.$vuetify.breakpoint.mdAndDown ? false : true;

  private created() {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      this.$store.dispatch("users/getUserById", user.id);
    }
    this.$store.dispatch("bill/getPendingBills", {
      operation: "plus",
      page: 1,
      size: 1,
    });
  }

  @Watch("drawer")
  private openSidebar(): void {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.readyOpen = !this.readyOpen;
    }
  }

  private get route(): string | null | undefined {
    return this.$route.name;
  }
  private get user(): User {
    return this.$store.getters["users/getUser"];
  }
  private get profileBadge() {
    return this.user && !this.user.address;
  }
  private get userAlias(): string {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.alias;
    } else {
      return "";
    }
  }
  get pendingBills(): number {
    const bills = this.$store.getters["bill/getPendingBills"];
    return bills?.totalItems || 0;
  }
  private get role(): string {
    return this.$store.getters["authentication/getRole"];
  }

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  private async logOut() {
    this.navigate("/home");
    await this.$store.dispatch("authentication/logOut").then(() => {
      location.reload();
    });
  }
}
