
import Component, { mixins } from "vue-class-component";
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import FeedbackDialog from "@/components/General/FeedbackDialog.vue";
import NewsletterDialog from "@/components/General/NewsletterDialog.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    FeedbackDialog,
    NewsletterDialog,
  },
})
export default class FooterLinks extends mixins(
  FormValidations,
  CustomDate,
  Navigation,
  StyleCheck
) {
  feedbackDialog = false;
  newsletterDialog = false;
}
